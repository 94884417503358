import React, { useState } from 'react'
import ChatBot, { Message } from 'react-chatbotify'
import { getContractAIData } from '../../../../../utilities/microsoft-graph-helpers'
import { RDPaletteLight } from '../../../../services/ThemeService'
import { generateWordDoc } from '../../../../../utilities/office-apis-helpers'
import {
  getDataWithoutStyles,
  getGeminiRequestPromptParts,
  getUserInputType,
  parseResponse,
} from './RDChatBotifyHelpers'
import RDChatBotifySummary from './RDChatBotifySummary'
import { Box } from '@mui/material'
import { RDGrid } from '../RDGrid'
import { PRO_MODEL } from '../../../AppHelpers'

interface RDChatBotifyState {
  disableUserInput: boolean
  options: string[]
  paths: any[]
  messages: Message[]
  sessionID: string
  loading: boolean
}

export interface RDChatBotifyProps {
  sessionID: string
  token: string
  setBotOpen: React.Dispatch<React.SetStateAction<boolean>>
  cacheName: string
}

export interface GeminiRequestPromptParts {
  prefix: string
  userMessage: string
  suffix: string
  document: string
}

export interface GenerateAIRequestParams {
  contractId: number
  contract_prompt_text: string
  agent_model: string
}

const getInitialState = () => {
  return {
    disableUserInput: false,
    options: [],
    paths: [],
    messages: [],
    sessionID: '',
    loading: false,
  }
}

const RDChatBotify = (props: RDChatBotifyProps) => {
  const [rdChatBotifyState, setRdChatBotifyState] = useState<RDChatBotifyState>(getInitialState())
  //console.log(rdChatBotifyState, setRdChatBotifyState)

  const flow = {
    start: {
      message: `Welcome to the Contract Assistant AI!  I have finished processing your document. You can ask me specific questions on the document or request summaries of sections or clauses.`,
      path: 'loop',
    },
    loop: {
      message: (params) => {
        run(params)
      },
      path: 'loop',
    },
  }

  const run = (params) => {
    setRdChatBotifyState((prev) => ({ ...prev, loading: true }))
    sendRequest({
      userInput: params?.userInput,
      params,
    })
  }

  const sendRequest = async ({ userInput, params }) => {
    let userInputType = getUserInputType(userInput)
    console.log(userInputType)
    console.log(params)
    setRdChatBotifyState((prev) => ({ ...prev, loading: true, disableUserInput: true }))
    try {
      await Word.run(async (context) => {
        const body = context.document.body

        context.load(body, ['text', 'style'])
        await context.sync()
        const trackedChanges = body.getReviewedText('Current')
        await context.sync()
        const docText = trackedChanges.value
        console.log('docText', docText)
        const { userMessage } = getGeminiRequestPromptParts(docText, userInput)
        // const data: GenerateAIRequestParams = {
        //   contractId: 43569,
        //   contract_prompt_text: prefix + userMessage + suffix + document,
        //   agent_model: 'gemini-1.5-pro-002',
        // }
        const data = {
          context: userMessage,
          context_cache_name: props.cacheName,
          model_name: PRO_MODEL,
        }
        let response = await getContractAIData(
          `https://${process.env.REACT_APP_RAINAI}/sendContextCacheChat`,
          props.token,
          data
        )
        const genDoc = generateWordDoc(response.data)
        console.log(genDoc)
        const parsedResp = parseResponse(response)
        setRdChatBotifyState((prev) => ({ ...prev, loading: false, disableUserInput: false }))

        if (response) {
          if (parsedResp.updatedContract) {
            const oldData = docText
            const oldDataWithoutStyles = getDataWithoutStyles(oldData)
            params.injectMessage(
              <RDGrid direction='column' justifyContent={'space-between'}>
                <RDGrid
                  style={{
                    marginLeft: '16px',
                    marginTop: '8px',
                    backgroundColor: RDPaletteLight.WhiteSmoke,
                    borderRadius: '22px',
                    padding: '12px 16px',
                    maxWidth: '75%',
                  }}>
                  <RDChatBotifySummary response={parsedResp} docText={oldDataWithoutStyles}></RDChatBotifySummary>
                </RDGrid>
              </RDGrid>
            )
          } else {
            params.injectMessage(response.data)
          }
        } else {
          console.error('Unexpected response structure:', response)
          params.injectMessage('Unexpected response from server.')
          setRdChatBotifyState((prev) => ({ ...prev, loading: false, disableUserInput: false }))
        }
      })
    } catch (err) {
      console.error('Error during Axios request:', err)
      params.injectMessage('An error occurred while processing your request.')
    }
    return 'Something Happened'
  }

  const LoaderOverlay = ({ loading }) => {
    return (
      loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: 0,
            width: '50%',
            height: '50vh',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}></Box>
      )
    )
  }

  return (
    <RDGrid container direction='column' nopaddingtop='true' style={{ width: '100%', left: '50%', height: '100%' }}>
      <Box
        id='chatdraggable'
        style={{
          height: '100%',
          width: '100%',
        }}>
        <LoaderOverlay loading={rdChatBotifyState.loading}></LoaderOverlay>
        <ChatBot
          settings={{
            general: {
              embedded: true,
              showHeader: false,
              primaryColor: '#34a1d5',
              secondaryColor: '#004991',
              fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
              showFooter: false,
              showInputRow: true,
            },
            audio: {
              disabled: true,
            },
            voice: {
              disabled: false,
            },
            footer: {
              text: 'RaindropAI',
            },
            chatHistory: {
              autoLoad: true,
              maxEntries: 10,
            },
          }}
          flow={flow}
          styles={{
            chatWindowStyle: {
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0)',
              height: '100%',
              borderRadius: 'none',
              boxShadow: 'none',
            },
            botBubbleStyle: {
              color: RDPaletteLight.Black,
              backgroundColor: RDPaletteLight.WhiteSmoke,
              padding: '10px 14px',
              margin: '8px 0px 0px 12px',
              minWidth: '10%',
              maxWidth: '80%',
            },
            userBubbleStyle: {
              padding: '10px 14px',
              margin: '8px 12px 0px 0px',
              minWidth: '10%',
              maxWidth: '80%',
            },
            chatInputAreaStyle: {
              borderRadius: '12px',
            },
            sendButtonHoveredStyle: {
              borderRadius: '16px',
            },
            sendButtonStyle: {
              borderRadius: '16px',
            },
            botOptionStyle: {
              padding: 'unset',
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            botOptionHoveredStyle: {
              padding: 'unset',
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '16px',
              paddingRight: '16px',
            },
            voiceButtonStyle: {
              borderRadius: '16px',
            },
            voiceButtonDisabledStyle: {
              borderRadius: '16px',
            },
            chatInputContainerStyle: {
              padding: '8px 10px',
            },
          }}
        />
      </Box>
    </RDGrid>
  )
}

export default RDChatBotify
