import React, { useState } from 'react'
import { TopPaneComponent } from './TopPane/TopPaneComponent'
import { AppState, ClauseList } from './AppHelpers'
import { TopMenuBar } from './TopMenuBar'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import RDChatBotify from './Shared/Wrappers/RDChatBotify/RDChatBotify'
import { RDButton } from './RDButton'
import { Box, Dialog, DialogContent } from '@mui/material'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import { Close } from '@material-ui/icons'
import { RDPaletteLight } from '../services/ThemeService'
import { v4 as uuidv4 } from 'uuid'
import { RDIcon } from './Shared/Wrappers/RDIcon'

interface AddInBodyProps {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
  clauseList: ClauseList[]
}

const AddInBody = (props: AddInBodyProps) => {
  const { state, clauseList, setState } = props
  const [botOpen, setBotOpen] = useState<boolean>(false)

  return (
    <RDGrid style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
      <RDGrid container style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
        <TopMenuBar appSetState={setState} appState={state} />
        <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />
        {botOpen && (
          <MemoizedDraggableResizableDialog open={botOpen} onClose={() => setBotOpen(false)}>
            <RDChatBotify
              sessionID={uuidv4()}
              token={state.authToken}
              cacheName={state.contextCacheName}
              setBotOpen={setBotOpen}></RDChatBotify>
          </MemoizedDraggableResizableDialog>
          //   </Box>
          // </Modal>
        )}
      </RDGrid>
      {!botOpen && state.contextCacheName?.length > 0 && (
        <RDButton
          onClick={() => setBotOpen(true)}
          sx={{
            position: 'relative',
            top: '-2%',
            left: '98%',
            transform: 'translate(-100%, -100%)',
            width: '30px',
            height: '30px',
            padding: 0,
            boxShadow: 24,
            zIndex: 2000,
            paddingTop: 0,
          }}>
          <img height='28px' width='28px' src={'assets/raindrop.png'} alt={'raindrop'} />
        </RDButton>
      )}
    </RDGrid>
  )
}

// const SystemSetupTabs = (state: AppState, clauseList: ClauseList[]) => [
//   {
//     name: 'risk_analysis',
//     label: 'Risk Analysis',
//     component: <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />,
//   },
//   {
//     name: 'integration',
//     label: 'Assistant',
//     component: <RDChatBotify sessionID='aasdfgd78' token={state?.authToken}></RDChatBotify>,
//   },
// ]
const propsAreEqual = (prevProps: any, nextProps: any) => {
  return prevProps.open === nextProps.open
}

const DraggableResizableDialog = ({ open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      id='rd-chatbot-dialog'
      onClose={onClose}
      slotProps={
        {
          paper: {
            style: {
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            },
          },
        } as any
      }
      fullScreen={false}
      PaperComponent={(props) => (
        <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
          <ResizableBox
            width='100%'
            height={400}
            className='rd-chatbot-draggable'
            minConstraints={[300, 200]}
            maxConstraints={[800, Infinity]}
            style={{
              backgroundColor: RDPaletteLight.paper,
              padding: 0,
              borderRadius: 9,
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
            resizeHandles={['nw']}
            {...props}
          />
        </Draggable>
      )}>
      <Box
        display='flex'
        alignItems='center'
        id='draggable-dialog-title'
        justifyContent='space-between'
        style={{
          paddingBottom: 5,
          paddingTop: 5,
          background: RDPaletteLight.link,
          cursor: 'move',
        }}>
        <RDIcon name='rain' size='xl' scale={3} style={{ marginTop: '3px', marginLeft: 40 }} />
        <Box flexGrow={1}></Box>
        <RDButton
          style={{ height: 40, width: 40, color: 'black', margin: 0 }}
          onClick={(evt) => {
            evt.stopPropagation()
            onClose()
          }}>
          <Close style={{ height: 20, width: 20 }} />
        </RDButton>
      </Box>
      <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  )
}

export default AddInBody

export const MemoizedDraggableResizableDialog = React.memo(DraggableResizableDialog, propsAreEqual)
